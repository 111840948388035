body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;

  background: white;
}

.application-definition {
  margin: 16px 8px;
  padding: 16px;
}

.create-application-job-paper {
  padding: 16px;
}

a {
  color: rgb(219, 56, 111);
  text-decoration: none;
  border-bottom: rgb(219, 56, 111) dashed 1px;
}

a.image {
  border: none;
}

.not-active {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  border-bottom: grey dashed 1px;
  color: grey;
}

.title {
  font-size: 20px;
  line-height: 1.33;
  color: rgba(0, 0, 0, 0.87);
}

.headline {
  font-size: 24px;
  line-height: 1.33;
  color: rgba(0, 0, 0, 0.87);
}

.sub-header-discreet {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.54);
}

.row-no-sides {
  margin-left: -1rem;
  margin-right: -1rem;
}

@media only screen and (min-width: 640px) {
  .visible-xs {
    display: none;
  }
}

@media only screen and (max-width: 639px) {
  .hidden-xs {
    display: none;
  }
}

.pull-right {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.bold {
  font-weight: bold;
}

.interpret-button {
  margin-top: 16px;
}

.box {
  width: 100px;
  height: 100px;
  background: red;
  transform-origin: 50% 50%;
}

.img-responsive.mobile {
  display: none;
}

@media only screen and (max-device-width: 480px) {
  .img-responsive.desktop {
    display: none;
  }
  .img-responsive.mobile {
    display: block;
  }
}

.logo-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

.explorer-image-mobile {
  width: 25%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2px;
  height: auto;
}

.explorer-image-desktop {
  width: 16%;
  height: auto;
}

.ScriptOpCodeList {
  margin: 0;
  border-radius: 2px;
}

.OpCode {
  padding: 1px 4px 4px;
  box-sizing: border-box;
  border: 1px solid #EEE;
  display: inline-block;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  box-shadow: 0 1px 5px 0 black;
  max-width: 100%;
}

.CryptoOps {
  background: #0b97c4;
}

.StackOps {
  background: #23c426;
}

.FlowControlOps {
  background: #c42db3;
}

.BitwiseLogicOps {
  background: #4ac4b2;
}

.ArithmeticOps {
  background: #c45f1e;
}

.LocktimeOps {
  background: #c40a46;
}

.ReservedOps {
  background: #bbbbc4;
}

.ConstantOps {
  background: #77c427;
}

.Values {
  background: #18c47d;
}

.PseudoOps {
  background: #77c427;
}

.SpliceOps {
  background: #18c47d;
}

.btc-address {
  color: black;
  font-style: italic;
  font-size: 90%;
  padding-top: 10px;
  padding-bottom: 4px;
}

.btc-amount {
  color: black;
  padding-top: 2px;
  padding-bottom: 4px;
}

.btc.spent {
  color: darkred;
  vertical-align: "middle";
}

.btc.to {
  color: darkgreen;
}

.button-divider {
  width: 8px;
  height: auto;
  display: inline-block;
}

.TxVersion {
  color: white;
  background: green;
}

.TxFlag {
  color: white;
  background: darkred;
}

.TxListCount {
  color: white;
  background: darkorange;
}

.TxInputPreviousTxId {
  color: white;
  background: darkblue;
}

.TxInputPreviousTxOutput {
  color: white;
  background: darkcyan;
}

.TxInputUnLockingScript {
  color: white;
  background: darkmagenta;
}

.TxInputSequence {
  color: blue;
  background: cyan;
}

.TxOutputValue {
  color: white;
  background: green;
}

.TxOutputLockingScript {
  color: white;
  background: red;
}

.TxWitness0 {
  color: black;
  background: yellow;
}

.TxWitness1 {
  color: white;
  background: black;
}

.TxLockTime {
  color: white;
  background: darkred;
}
